import { Dayjs } from 'dayjs'
import {
  cloneDeep,
  defaultsDeep,
  find,
  flatMap,
  forEach,
  intersection,
  isNumber,
  isString,
  sum,
  uniqBy,
} from 'lodash'
import { Nilable } from 'tsdef'
import { PartialDeep } from 'type-fest'
import { proxy, ref, snapshot } from 'valtio'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { apirc } from '~/configs/apirc'
import { remoteSupportSymbols } from '~/configs/remoteSupportSymbols'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { createDatafeedStore } from '~/modules/SDK/Chart2/createDatafeedStore'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { ChartingDatafeedModule } from '~/modules/SDK/chart4/ChartingDatafeedModule'
import { chartingrc } from '~/modules/SDK/chart4/chartingrc'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { SocketChannel } from '~/modules/SDK/socket/SocketChannel'
import { symbolStringPrune } from '~/modules/SDK/Symbol/symbolStringPrune'
import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument'
import {
  ChartingLibraryWidgetConstructor,
  IChartingLibraryWidget,
  ResolutionString,
} from '~/public/charting_library_v19/charting_library/charting_library'
import { __IS_CLIENT__ } from '~/utils/__IS_CLIENT__'
import dayAPI from '~/utils/dayAPI'
import { delay } from '~/utils/delay'

const __UNSET__ = '__UNSET__'

export class ChartingModule {
  /** 作用中商品 */
  public symbol: ChartTypes.WidgetOptions['symbol'] = 'TX-1'

  /** 作用中週期 */
  public interval: ChartTypes.WidgetOptions['interval'] = '5'

  /** 作用中 charting widget instance */
  public widget: null | IChartingLibraryWidget = null

  /** Widget 作用參數 */
  public widgetOptions: Partial<ChartTypes.WidgetOptions> = {}

  /** Datafeed 模塊 */
  public datafeed?: null | ChartingDatafeedModule

  /** 預加載的 indicators 在 `create()` 配置它，在後續才可以作 `addIndicators()` */
  public indicatorsPreloaded: ChartTypes.Indicator[] = []

  /** 首次 render 預設要顯示的 indicators */
  public indicators: ChartTypes.Indicator[] = []

  /** 作用中的伺服器 */
  public server = apirc.chartServer[0]

  /** 可用的伺服器 */
  public serverList = apirc.chartServer

  /**
   * # 一種 `calculateHistoryDepth` 的解決方案
   *
   * @example
   *   // 配置 VisibleRange 範圍為 1日內的 K棒
   *   // 當執行 {@link ChartingModuleOfStrategy.setStrategy}
   *   // 會先幫你設 7日，延遲以後再設回 1日
   *   // 這可以使某些指標，能夠獲得7日K棒順利運算
   *
   *   {
   *     calcFrom: dayAPI().subtract(7, 'day')
   *   }
   */
  public calcFrom: null | Dayjs = null

  /**
   * @example
   *   // ## 主圖你設定% 副圖自動
   *
   *   panesRatio: 70,
   *
   * @example
   *   // ## 主圖自動 副圖你設定%
   *
   *   panesRatio: [15, 15, 15],
   */
  public panesRatio?: number | number[]

  /** Darktheme 要用套用的，你可以透過 UI 置換這些，再執行 `toggleDarkTheme()` */
  public darkOverrides = chartingrc.widgetOverridesForKbars.dark

  /** Lighttheme 要用套用的，你可以透過 UI 置換這些，再執行 `toggleDarkTheme()` */
  public lightOverrides = chartingrc.widgetOverridesForKbars.light

  /** 可用的策略集 */
  public strategyConfigs: ChartTypes.StrategyConfig[] = []
  /** 選中的策略集 */
  public strategySelected!: ChartTypes.StrategyConfig

  /** Charting 出現吧！Charting！ */
  public create() {
    debugAPI.chart4.log('create()', this.widgetOptions)
    const Widget = globalThis.TradingView.widget as unknown as ChartingLibraryWidgetConstructor

    const me = useMeStore.getState()
    const theme = useThemeStore.getState().theme

    // widgetOptions 優先，如未給，以 themeStore 作為預設
    if (!this.widgetOptions?.theme) {
      this.widgetOptions.theme = theme === 'dark' ? 'Dark' : 'Light'
    }

    // charting.state 的同步
    this.symbol = this.widgetOptions.symbol || this.symbol
    this.interval = this.widgetOptions.interval || this.interval

    /** 根據開發者給予的策略組，自動 preloaded 所有配置的客製指標組 */
    const indicatorsPreloaded = this.indicatorsPreloaded

    /** 根據開發者給予的策略組，自動預設了第一組客製指標組 */
    const indicatorsInitials = this.indicators

    // 建立 datafeed / socket
    // this.datafeed = ref(createDatafeedStore(this.server))
    this.datafeed = new ChartingDatafeedModule({
      server: this.server,
    })

    const defaultsOptions: ChartTypes.WidgetOptions = {
      ...this.widgetOptions,
      container: this.widgetOptions?.container || 'charting',
      autosize: true,
      symbol: this.widgetOptions?.symbol || 'TX-1',
      interval: this.widgetOptions?.interval || '5',
      timezone: 'exchange',
      time_frames: [{ text: '1d', resolution: '5' as AnyFIXME, description: '1 Days' }],
      datafeed: this.datafeed.create(),
      library_path: '/charting_library_v19/charting_library/',
      locale: 'zh_TW' as AnyFIXME,
      theme: this.widgetOptions?.theme || 'Light',
      charts_storage_url: apirc.chartsStorageUrl.baseUrl,
      charts_storage_api_version: '1.2',
      client_id: this.widgetOptions?.client_id || me.agentName || __UNSET__,
      user_id: this.widgetOptions?.user_id || me.meUserState?.uid || __UNSET__,
      enabled_features: [
        'chart_property_page_trading',
        'hide_last_na_study_output',
        'fix_left_edge',
      ],
      disabled_features: [
        'main_series_scale_menu',
        'header_fullscreen_button',
        'header_screenshot',
        'header_fullscreen_button',
        //'legend_widget',
        ...(this.widgetOptions?.isMobile ? ['left_toolbar'] : []),
        ...(this.widgetOptions?.enableVolumeIndicator
          ? []
          : ['create_volume_indicator_by_default']),
        ...(this.widgetOptions?.disabledSymbolSearch ? ['header_symbol_search'] : []),
        ...(this.widgetOptions?.disabledLeftToolbar ? ['left_toolbar'] : []),
        ...(this.widgetOptions?.disabledGoToDate ? ['go_to_date'] : []),
        ...(this.widgetOptions?.disabledLegendMenu ? ['legend_context_menu'] : []),
        ...(this.widgetOptions?.disabledScalesMenu ? ['scales_context_menu'] : []),
        ...(this.widgetOptions?.disabledPaneMenu ? ['pane_context_menu'] : []),
        ...(this.widgetOptions?.disabledHeaderWidget ? ['header_widget'] : []),
        ...(this.widgetOptions?.disabledHeaderInterval ? ['header_resolutions'] : []),
        ...(this.widgetOptions?.disabledHeaderChartType ? ['header_chart_type'] : []),
        ...(this.widgetOptions?.disabledHeaderCompare ? ['header_compare'] : []),
        ...(this.widgetOptions?.disabledHeaderSaveload ? ['header_saveload'] : []),
        ...(this.widgetOptions?.disabledTimeframesToolbar ? ['timeframes_toolbar'] : []),
      ],
      overrides: {
        /** 底層我們默認預設的最簡漏樣式，主要改為紅色是漲，綠色是跌 */
        'scalesProperties.showSeriesLastValue': true,
        'mainSeriesProperties.candleStyle.upColor': '#d75442',
        'mainSeriesProperties.candleStyle.downColor': '#6ba583',
        'mainSeriesProperties.candleStyle.borderUpColor': '#5b1a13',
        'mainSeriesProperties.candleStyle.borderDownColor': '#225437',
        'mainSeriesProperties.candleStyle.wickUpColor': 'rgba( 115, 115, 117, 1)',
        'mainSeriesProperties.candleStyle.wickDownColor': 'rgba( 115, 115, 117, 1)',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 25,
        'timeScale.rightOffset': 20,
        ...this.widgetOptions.overrides,
        ...(this.widgetOptions.theme === 'Dark' ? this.darkOverrides : this.lightOverrides),
      },
      studies_overrides: {
        'volume.volume.color.0': '#6ba583',
        'volume.volume.color.1': '#d75442',
        'moving average.precision': 0,
        ...this.widgetOptions.studies_overrides,
      },
      customIndicators: indicatorsInitials,
      custom_indicators_getter: (pineJS: PineJS.This) => {
        return Promise.resolve(
          (indicatorsPreloaded || []).map(indicator => {
            const PineInstance = indicator(pineJS)

            return PineInstance
          }),
        )
      },
    }

    this.widgetOptions = defaultsOptions

    debugAPI.chart4.log(`create();ing`, {
      client_id: me.agentName,
      user_id: me.meUserState?.uid,
      indicatorsPreloaded,
      widgetOptions: this.widgetOptions,
      'widgetOptions.theme': this.widgetOptions.theme,
      'widgetOptions.overrides': this.widgetOptions.overrides,
      datafeed: this.widgetOptions?.datafeed,
      charting: this,
    })

    // 初始化 Chart
    const widget = (this.widget = ref(
      new Widget(this.widgetOptions as AnyFIXME),
    ) as IChartingLibraryWidget)

    // 當 Chart 初始完成，則開始 Charting 的一些底層基礎準備工作
    widget.onChartReady(() => {
      debugAPI.chart4.log(`onChartReady() -> .applyOverrides()`, {
        widget,
        overrides: this.widgetOptions.overrides,
      })

      // 渲染我們自己的預設樣式
      widget.applyOverrides({
        ...this.widgetOptions.overrides,
      })

      debugAPI.chart4.log(`onChartReady() -> datafeed.connect()`, { datafeed: this.datafeed })

      // 開始運作 datafeed 開始處理 kbars
      // datafeed.getState().connect()
      this.datafeed?.start()

      const chart = widget.activeChart()

      chart.onIntervalChanged().subscribe(null, (newIntervalValue: any) => {
        this.interval = newIntervalValue

        if (this.widgetOptions) this.widgetOptions.interval = newIntervalValue

        debugAPI.chart4.log(`onIntervalChanged().subscribe()`, {
          newIntervalValue: newIntervalValue,
        })
      })

      chart.onSymbolChanged().subscribe(null, () => {
        this.symbol = symbolStringPrune(chart.symbol() || '')

        if (this.widgetOptions) this.widgetOptions.symbol = this.symbol

        debugAPI.chart4.log(`onSymbolChanged().subscribe()`, { newSymbolValue: this.symbol })
      })

      this.updateFromState()
    })
  }

  public async updateFromState() {
    debugAPI.chart4.log(`updateFromState()`)
    await this.replaceIndicators(this.indicators).then(() => {
      if (this.panesRatio) {
        this.changePanesRatio(this.panesRatio)
      }
    })

    this.widget?.applyOverrides({
      ...this.widgetOptions.overrides,
    })

    this.changeInterval(this.interval)
    this.changeSymbol(this.symbol)

    if (this.calcFrom) {
      await delay(1000)
      await this.setVisibleRange(this.calcFrom)
      this.widget?.activeChart().executeActionById('chartReset')
    }
  }

  /** 在 destroy 之後，才能夠乾淨地再一次 create */
  public destroy() {
    debugAPI.chart4.log(`destroy()`)
    // this.datafeed?.getState().disconnect()
    // this.datafeed = null
    this.datafeed?.stop()
    this.datafeed = null
    this.widgetOptions.datafeed = undefined
    this.widgetOptions = {}
    this.widget?.remove()
    this.widget = null
    debugAPI.chart4.log(`destroy();ed`)
  }

  /** # 當前圖表中，是否包含你給予的指標們 */
  public isIndicatorsIncludes(indicators: ChartTypes.Indicator[]) {
    const currentIndicators = intersection(indicators, this.getActiveIndicators())

    for (const currentIndicator of indicators) {
      const included = find(
        currentIndicators,
        $$ => $$.displayName === currentIndicator.displayName,
      )

      if (!included) return false
    }

    return true
  }

  /** 取得目前正在運作中的客製指標 */
  public getActiveIndicators() {
    const customIndicators: ChartTypes.Indicator[] = []

    this.widget
      ?.activeChart()
      .getAllStudies()
      .forEach(study => {
        this.indicatorsPreloaded.forEach(indicator => {
          if (indicator.id === study.name) {
            customIndicators.push(indicator)
          }
        })
      })

    return customIndicators
  }

  /** 「Toggle 新增或移除來回切換」，你指定的 indicator */
  public toggleIndicators(indicators: ChartTypes.Indicator[]) {
    forEach(indicators, indicator => {
      const activeIndicatorFound = this.getActiveIndicators().find(
        activeIndicator => activeIndicator.id === indicator.id,
      )

      if (activeIndicatorFound) {
        this.removeIndicators([activeIndicatorFound])
      } else {
        this.addIndicators([...indicators])
      }
    })
  }

  /** # 確保你給予的指標們，會被加入到圖表中，但已存在者，不會重複被加入 */
  public ensureIndicators(indicators: ChartTypes.Indicator[]) {
    const indicatorsToEnsure = indicators
      .map(indicator => {
        const hadIndicator = this.getActiveIndicators().find($$ => $$.id === indicator.id)

        if (!hadIndicator) {
          return indicator
        }
      })
      // 不要加入 undefined；undefined 就是已經存在的指標
      .filter($ => !!$) as ChartTypes.Indicator[]

    return this.addIndicators(indicatorsToEnsure)
  }

  /**
   * 加入 indicators 到 Charting
   *
   * - 加載過程是 異步，所以返回 promise
   */
  public async addIndicators(indicators: ChartTypes.Indicator[] = []) {
    debugAPI.chart4.log(`addIndicators()`)
    const chart = this.widget?.activeChart()

    // 轉換 `'1d'` 成為純數字 `1440`
    const intervalNumber =
      {
        '1h': 60,
        '2h': 120,
        '3h': 180,
        '4h': 240,
        '8h': 480,
        '1d': 1440,
      }[String(this.interval).toLowerCase()] || Number(this.interval)

    const indicatorIDs = indicators
      .filter(indicatorFN => {
        const hasStudyName = !!indicatorFN?.id || !!indicatorFN.name

        const shouldActive = indicatorFN.enabledOn?.(
          this.symbol,
          remoteSupportSymbols[this.symbol],
          SocketChannel,
          intervalNumber,
          fr_instrument.getSymbol(this.symbol),
        )

        if (shouldActive === false) {
          return false
        }

        // 指標的主題色系是否與 charting 的主題色系一致？若指標無指定色系，則通過
        const allowedIndicatorTheme = !indicatorFN.config.filterOnTheme
          ? true
          : this.widgetOptions.theme?.toUpperCase() ===
            indicatorFN.config.filterOnTheme?.toUpperCase()

        if (allowedIndicatorTheme === false) return false

        return hasStudyName
      })
      .map(indicatorFN => {
        const fnId = indicatorFN?.id?.toUpperCase() || indicatorFN.name.toUpperCase()

        /**
         * 兼容兩種同名指標，同時被 import 在單支檔案模組時，被 webpack 重新命名成 sg1_sg1 的情況
         *
         * @example
         *   import { sg1 as sgHyt888 } from '~/indicators/hyt888/sg1'
         *   import { sg1 as sgGood178 } from '~/indicators/good178/sg1'
         *
         *   const defaultsConfigs = {
         *     good178: {
         *       get customIndicators() {
         *         return [sgGood178]
         *       },
         *     },
         *     hyt888: {
         *       get customIndicators() {
         *         return [sgHyt888]
         *       },
         *     },
         *   }
         */
        if (!fnId.includes('_')) {
          return fnId
        }

        return fnId.split('_')[0]
      })

    // FIXME:
    // 以下這串，是因為 changeIndicator（aka createStudy）是異步的
    // 他的 promise resolve 時機應該是有bug，
    // 當 `changeIndicator()` 馬上接 `changePaneRatio()`
    // 會拿不到新的 indicator 的 `paneHeight`
    // 因此加上 setTimeout 讓它可以拿到 `paneHeight`
    return new Promise((resolve, reject) => {
      Promise.all(
        indicatorIDs.map(studyName => {
          return chart?.createStudy(studyName, false, false)
        }),
      ).then(entityIds => {
        setTimeout(() => {
          this.indicators = this.getActiveIndicators()
          resolve(entityIds)
        }, 10)
      })
    })
  }

  /**
   * # 移除 indicators
   *
   * @example
   *   const indicator = createIndicator({ id: 'asiajye-super-trend' })
   *
   *   charting.removeIndicators([indicator])
   */
  public removeIndicators(indicators: ChartTypes.Indicator[]) {
    const indicatorsToRemove = indicators
      .map(indicator => {
        const hadIndicator = this.getActiveIndicators().find($$ => $$.id === indicator.id)

        if (hadIndicator) {
          return indicator
        }
      })
      .filter($ => !!$) as ChartTypes.Indicator[]

    for (const iteratorToRemove of indicatorsToRemove) {
      this.removeIndicatorsById(new RegExp(iteratorToRemove.id, 'i'))
    }
  }

  /**
   * # 移除 indicators
   *
   * @example
   *   const indicator = createIndicator({ id: 'asiajye-super-trend' })
   *
   *   charting.removeIndicatorsById(new RegExp(indicator.id))
   *   charting.removeIndicatorsById(new RegExp(/futures--/i))
   *   charting.removeIndicatorsById(new RegExp(/trends/i))
   */
  public removeIndicatorsById(
    /** 預設 `/futures--/` 即只移除由 `createIndicator()` 所產生的指標，不要影響到客戶所自行添加並儲存為版面的指標 */
    nameIfIncludes = /futures--/,
  ) {
    debugAPI.chart4.log(`removeIndicatorsById()`)
    const chart = this.widget?.activeChart()

    if (!chart) return

    chart.getAllStudies().forEach(study => {
      const names = this.indicatorsPreloaded.map(fn => (fn.id || fn.name).toLowerCase())
      if (nameIfIncludes.test(study.name) && names?.includes(study.name.toLowerCase())) {
        chart.removeEntity(study.id)
      }
    })

    this.indicators = this.getActiveIndicators()
  }

  /** 先移除剩餘指標，再加入新的 */
  public async replaceIndicators(
    /** 替換的指標 */
    indicators: ChartTypes.Indicator[],
    /** 預設 `/futures--/` 即只移除由 `createIndicator()` 所產生的指標，不要影響到客戶所自行添加並儲存為版面的指標 */
    nameIfIncludes = /futures--/,
  ) {
    this.removeIndicatorsById(nameIfIncludes)
    await this.addIndicators(indicators)
  }

  public changeSymbol(symbol: string) {
    debugAPI.chart4.log(`${this.changeSymbol.name}()`, { '$(symbol<)': symbol })
    this.symbol = symbol

    try {
      this.widget?.activeChart().setSymbol(symbol as string, () => {
        'noop'
      })
    } catch (error) {
      debugAPI.chart4.log(`${this.changeSymbol.name}()`, { '$(symbol<)': symbol, error })
      // 不報錯中斷，讓它頂多沒反應？
    }
  }

  public changeInterval(interval: string | number) {
    debugAPI.chart4.log(`${this.changeInterval.name}()`, { '$(interval<)': interval })
    this.interval = interval

    this.widget?.setSymbol(this.symbol, interval as ResolutionString, () => {
      // noop
    })
  }

  /**
   * 以高度百分比來設定「主圖」或「副圖」的高度
   *
   * 相對於內建式是以高度絕對值px來改變高度，你不再須要自己算高度的絕對值px單位
   *
   * @example
   *   // ## 設定主圖高度70%，副圖高度30%去平均
   *
   *   chart.changePaneRatio(70) // 主圖70,副圖30
   *
   * @example
   *   // ## 設定副圖們高度為主，分別高度為 20%,10%,10%
   *   // 主圖則自動以剩餘的 60% 去填滿（100-20-10-10=60）
   *
   *   chart.changePaneRatio([20, 10, 10]) // 主圖60,副圖20,副圖10,副圖10
   */
  public changePanesRatio(heightRatio: number | number[]) {
    const isTargetMainPane = isNumber(heightRatio)

    // 以主圖配置為主
    if (isTargetMainPane) {
      const panesHeightValues = this.widget?.activeChart().getAllPanesHeight() || []

      /** 總體可分配總高 */
      const windowHeight = sum(panesHeightValues)

      /** 主圖被分配之高度 */
      const mainHeight = (windowHeight * heightRatio) / 100

      /** 副圖可分配總高 */
      const panesHeight = windowHeight - mainHeight

      /** 每個副圖的平均高 */
      const paneHeight =
        /** 所以副圖的總高 */
        panesHeight /
        /** 總共有幾個副圖 */
        (panesHeightValues.length - 1)

      // 一口氣配置所有的 pane 高度
      this.widget?.activeChart().setAllPanesHeight(
        panesHeightValues.map((originHeight, paneIndex) => {
          // 主圖
          if (paneIndex === 0) {
            return mainHeight
          }

          // 副圖
          return paneHeight
        }),
      )

      debugAPI.chart2.log(`changePaneRatio(heightRatio = ${heightRatio})`)
    }
    // 以指定副圖配置為主
    else {
      const panesHeightValues = this.widget?.activeChart().getAllPanesHeight() || []

      /** 總體可分配總高 */
      const windowHeight = sum(panesHeightValues)

      /** 主圖要分配的勝餘% */
      const mainHeight = (windowHeight * (100 - sum(heightRatio))) / 100

      // 一口氣配置所有的 pane 高度
      this.widget?.activeChart().setAllPanesHeight([
        mainHeight,
        ...heightRatio.map(ratio => {
          const paneHeight = (windowHeight * ratio) / 100
          return paneHeight
        }),
      ])

      debugAPI.chart2.log(`changePaneRatio(heightRatio = ${heightRatio})`)
    }
  }

  /**
   * # 調整圖表K棒顯示的時間區間
   *
   *      ### 可以直接傳入 dayjs 物件，而不需使用 Date 物件去作轉換
   *
   * @example
   *   // ## 設定區間為「從7天前」到「現在」
   *
   *   const from = dayAPI().subtract(7, 'day')
   *
   *   charting.setVisibleRange(from)
   */
  public async setVisibleRange(from: Dayjs) {
    const fromFormatted = from.toDate().getTime() / 1000
    const nowFormatted = dayAPI().toDate().getTime() / 1000

    return await this.widget?.activeChart().setVisibleRange({
      from: fromFormatted,
      to: nowFormatted,
    })
  }

  /** 切換伺服器 */
  public switchServer(target: typeof this.server) {
    debugAPI.chart4.log(`switchServer(target)`, { target })
    const found = this.serverList?.find(item => item.label === target?.label)

    if (!found) {
      console.error(debugAPI.chart4.logger.namespace, {
        找不到你要的伺服器: target,
        回滾到預設伺服器: apirc.chartServer[0],
      })

      this.server = apirc.chartServer[0]

      return
    }

    this.server = found

    this.destroy()
    this.create()
  }

  /**
   * 根據 `this.widgetOptions.theme` 以及 `this.darkOverrides` 或 `this.lightOverrides` 來置換顏色
   *
   * @example
   *   // 變成 darkmode
   *   onClick={() => {
   *   charting.setThemeMode('dark')
   *   charting.updateFromThemeMode()
   *   }}
   */
  public updateFromThemeMode() {
    if (!__IS_CLIENT__) return
    debugAPI.chart4.log(`updateFromThemeMode()`)
    const widget = this.widget

    try {
      if (this.widgetOptions.theme) {
        debugAPI.chart4.log(`updateFromThemeMode(); widget?.changeTheme(themeKey)`, {
          themeKey: this.widgetOptions.theme,
          widget,
        })
        widget?.changeTheme(this.widgetOptions.theme)
      } else {
        debugAPI.chart4.log(`updateFromThemeMode(); widget?.changeTheme(themeKey)`, {
          themeKey: 'Light',
          widget,
        })
        widget?.changeTheme('Light')
      }
    } catch (error: any) {
      //
    }

    /**
     * `changeTheme()` 是 async 的，官方文件上可以 `.then` 但實際 runtime 卻是 return void； 因此 setTimeout
     * 解決，但仍無法解決快速切換 page 時，引發錯誤的問題。
     *
     * 文件
     * https://github.com/cory8249/charting_library/wiki/Widget-Methods#changethemethemename-options
     */
    setTimeout(() => {
      try {
        debugAPI.chart4.log(`updateFromThemeMode() ... widget?.applyOverrides(overrides)`, {
          overrides: this.widgetOptions.overrides,
        })
        widget?.applyOverrides({ ...this.widgetOptions.overrides })
      } catch (error: any) {
        //
      }
    }, 1500)
  }

  public setThemeMode(themeKey: 'dark' | 'light') {
    debugAPI.chart4.log(`setThemeMode(themeKey)`, { themeKey })
    if (themeKey === 'dark') {
      this.widgetOptions.theme = 'Dark'

      this.widgetOptions.overrides = {
        ...this.widgetOptions.overrides,
        ...this.darkOverrides,
      }
    }

    if (themeKey === 'light') {
      this.widgetOptions.theme = 'Light'

      this.widgetOptions.overrides = {
        ...this.widgetOptions.overrides,
        ...this.lightOverrides,
      }
    }
  }

  public initStrategy(options: { configs: ChartTypes.StrategyConfig[] }) {
    debugAPI.chart4.log('initStrategy(options)', { options })

    this.strategyConfigs = options.configs

    // 預先替開發者裝載好 StrategyConfig 配置中的所有 indicators
    this.indicatorsPreloaded = uniqBy(
      [
        ...this.indicatorsPreloaded,
        ...flatMap(this.strategyConfigs.map(config => config.indicators)),
      ],
      item => item.id,
    )

    // 預設選用 configs 中的第一個吧
    this.setStrategy(this.strategyConfigs?.[0])
  }

  /** 改變選用的策略組 */
  public setStrategy(
    /** 給予 `StrategyConfig.displayName` 或直接給 `StrategyConfig` */
    strategy?: string | ChartTypes.StrategyConfig,
  ) {
    debugAPI.chart4.log(`setStrategy(strategy)`, { strategy })
    const yourSpecifiedStrategy = this.strategyConfigs.find(config => {
      const target_ = isString(strategy) ? strategy : strategy?.displayName
      return config.displayName === target_
    })

    const selectingConfig = yourSpecifiedStrategy || this.strategyConfigs[0]

    if (!yourSpecifiedStrategy) {
      console.warn(`找不到「指標策略集」`, {
        你傳入: strategy,
        可選的: this.strategyConfigs,
        建議: `你需要在 .initStrategy(...) 中，先預裝好「指標策略集」`,
      })
    }

    this.strategySelected = selectingConfig
    this.strategySelected.onLoad?.()

    this.symbol = selectingConfig.symbol || this.symbol
    this.interval = selectingConfig.interval || this.interval
    this.indicators = selectingConfig.indicators
    this.panesRatio = selectingConfig.panesRatio
  }

  public async updateFromStrategyState() {
    await this.updateFromState()

    const chart = this.widget?.activeChart()

    if (!chart) return

    // 某些指標可能需要有N日內的K棒資料才能運算
    if (this.strategySelected.calcFrom) {
      await delay(1000)
      await this.setVisibleRange(this.strategySelected.calcFrom)

      chart.executeActionById('chartReset')
    }
  }
}
